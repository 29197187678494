import React, {Component} from 'react';
import DirectoryContainer from '../Directory'
import CodeContainer from '../Code'
import RenderContainer from '../Render'
import HideContainer from '../Hide'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {nanoid} from 'nanoid'
import i18n from "i18next";
import './app.css';

class App extends Component {
    constructor(props){
        super(props);
        let selectedNodeId = localStorage.getItem('selectedNodeId') ?? null;
        let selectedNodeName = localStorage.getItem('selectedNodeName') ?? null;
        let [lines, variables] = this.getFileFromStorage(selectedNodeId);
        let filesIdCount = localStorage.getItem('filesIdCount') ?? 1;
        let files = localStorage.getItem('files') ? JSON.parse(localStorage.getItem('files')) : null;
        if(!files || Object.keys(files).length < 2){
            localStorage.clear();
            files = null;
            lines = [{text: '', id: nanoid()}];
            variables = '{}';
            selectedNodeName = selectedNodeId = null;
        }
        this.lines = lines;
        this.files = files ??
            {
                node0: {
                    children: []
                }
            };
        this.filesIdCount = parseInt(filesIdCount);
        this.state = {
            selectedNodeId: selectedNodeId,
            selectedNodeName: selectedNodeName,
            linesCheck: 0,
            filesCheck: 0,
            variables: variables,
            hide: {
                preset: "none",
                text: ""
            }
        }
        this.elements = {
            hide: React.createRef()
        }
        this.codeAddLines = this.codeAddLines.bind(this);
        this.removeLines = this.removeLines.bind(this);
        this.changeLine = this.changeLine.bind(this);
        this.setSelectedNode = this.setSelectedNode.bind(this);
        this.resetSelectedNode = this.resetSelectedNode.bind(this);
        this.addFileOrFolder = this.addFileOrFolder.bind(this);
        this.setFiles = this.setFiles.bind(this);
        this.setVariables = this.setVariables.bind(this);
        this.saveSelectedNode = this.saveSelectedNode.bind(this);
        this.setHideLoading = this.setHideLoading.bind(this);
        this.setHide = this.setHide.bind(this);
        this.setLines = this.setLines.bind(this);
        this.localStorageSafeSetItem = this.localStorageSafeSetItem.bind(this);
        this.addNotification = this.addNotification.bind(this);
    }

    componentDidMount(){
        window.addEventListener("beforeunload", (event) => {
            this.pageClosing(event);
        })
    }

    //citace https://github.com/szymonkaliski/react-window-mixins/issues/2#issuecomment-133290242
    pageClosing(event){
        let canClose = this.localStorageSafeSetItem('filesIdCount', this.filesIdCount)
            && this.localStorageSafeSetItem('files', JSON.stringify(this.files))
            && this.saveSelectedNode();
        if(!canClose){
            let confirmationMessage = i18n.t('app:labels.storage full');
            (event || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        }
    }

    saveSelectedNode(){
        if(this.state.selectedNodeId !== null){
            return this.localStorageSafeSetItem(this.state.selectedNodeId, JSON.stringify({
                lines: this.lines,
                variables: this.state.variables
            }));
        }
        return true;
    }

    // citace http://crocodillon.com/blog/always-catch-localstorage-security-and-quota-exceeded-errors
    isQuotaExceeded(error){
        var quotaExceeded = false;
        if(error){
            if(error.code){
                switch(error.code){
                    case 22:
                        quotaExceeded = true;
                        break;
                    case 1014:
                        // Firefox
                        if(error.name === 'NS_ERROR_DOM_QUOTA_REACHED'){
                            quotaExceeded = true;
                        }
                        break;
                }
            }
            else if(error.number === -2147024882){
                // Internet Explorer 8
                quotaExceeded = true;
            }
        }
        return quotaExceeded;
    }

    localStorageSafeSetItem(key, value){
        try{
            localStorage.setItem(key, value);
            return true;
        } catch(error){
            if(this.isQuotaExceeded(error)){
                this.addNotification(i18n.t('notificationLabels:app error'), i18n.t('app:notifications.errors.storage full'), 'danger', 5000);
            }
            else {
                this.addNotification(i18n.t('notificationLabels:app error'), i18n.t('app:notifications.errors.localstorage'), 'danger', 5000);
            }
            return false;
        }
    }

    // citace https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
    capitalize(s){
        if(typeof s !== 'string'){
            return s;
        }
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    addNotification(title, message, type, duration = 1500){
        Store.addNotification({
            title: this.capitalize(title),
            message: this.capitalize(message),
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration,
                onScreen: false
            }
        });
    }

    codeAddLines(id, text){
        let textSplit = text.replace(/[\r]/g, '').split('\n');
        this.lines[id].text = textSplit[0];
        for(let i = 1; i < textSplit.length; i++){
            this.lines.splice(++id, 0, {text: textSplit[i], id: nanoid()});
        }
        //calls setstate to show loading screen
        this.setHideLoading(textSplit.length > 1000).then(() => {
            this.setLines();
        })
        return [id, this.lines[id].text];
    }

    removeLines(id, count){
        //calls setstate to show loading screen
        this.setHideLoading(count > 5000).then(() => {
            this.lines.splice(id, count);
            this.setLines();
        })
    }

    changeLine(id, text){
        if(text.includes('\n')){
            this.codeAddLines(id, text);
        }
        else if(id < 0 || this.lines.length <= id){
            return;
        }
        else {
            this.lines[id].text = text;
            this.setLines();
        }
    }

    setLines(){
        if(!this.lines.length){
            this.lines = [{text: '', id: nanoid()}]
        }
        this.setState(function(prevState){
            return {
                linesCheck: prevState.linesCheck + 1
            }
        });
    }

    getFileFromStorage(id){
        let lines = [{text: '', id: nanoid()}];
        let variables = '{}';
        if(id){
            let file = localStorage.getItem(id);
            if(file){
                file = JSON.parse(file);
                if(file && file.lines && file.lines.length > 0 && file.variables){
                    lines = file.lines;
                    variables = file.variables;
                }
            }
        }
        return [lines, variables];
    }

    setSelectedNode(id, name){
        if(typeof this.files[id] === 'undefined' || typeof this.files[id]['children'] !== 'undefined'){
            this.resetSelectedNode();
            return;
        }
        this.localStorageSafeSetItem('selectedNodeId', id);
        this.localStorageSafeSetItem('selectedNodeName', name);
        const [newLines, newVariables] = this.getFileFromStorage(id);
        this.setHideLoading(newLines.length > 1000 || this.lines.length > 1000).then(() => {
            this.lines = newLines;
            this.setState(function(prevState){
                return {
                    ...prevState,
                    selectedNodeId: id,
                    selectedNodeName: name,
                    linesCheck: prevState.linesCheck + 1,
                    variables: newVariables
                }
            });
        });
    }

    resetSelectedNode(){
        this.setHideLoading(this.lines.length > 1000).then(() => {
            localStorage.removeItem('selectedNodeId');
            localStorage.removeItem('selectedNodeName');
            const [newLines, newVariables] = this.getFileFromStorage(0);
            this.lines = newLines;
            this.setState(function(prevState){
                return {
                    ...prevState,
                    selectedNodeId: null,
                    selectedNodeName: null,
                    linesCheck: prevState.linesCheck + 1,
                    variables: newVariables
                }
            });
        });
    }

    addFileOrFolder(newFile){
        if(typeof newFile.parent === 'undefined'
            || typeof this.files[newFile.parent] === 'undefined'
            || typeof this.files[newFile.parent].children === 'undefined'){
            return false;
        }
        this.filesIdCount = this.filesIdCount + 1;
        let newId = 'node' + this.filesIdCount;
        this.files[newId] = newFile;
        this.files[newFile.parent].children.push(newId);
        this.setFiles();
        return newId;
    }

    setFiles(){
        if(typeof this.files['node0'] === 'undefined'){
            this.files = {
                node0: {
                    children: []
                }
            };
        }
        this.setState(function(prevState){
            return {
                ...prevState,
                filesCheck: prevState.filesCheck + 1
            }
        })
    }

    downloadText(text, name){
        let downloadElement = document.createElement('a');
        downloadElement.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
        downloadElement.download = name;
        downloadElement.click();
    }

    setVariables(newVariables){
        this.setState(function(prevState){
            return {
                ...prevState,
                variables: newVariables
            }
        })
    }

    // citace https://stackoverflow.com/a/30327918/3586860
    setHideLoading(showLoading){
        return new Promise((resolve, reject) => {
            if(!showLoading){
                resolve();
            }
            else {
                this.setState({
                        hide: {
                            preset: 'all',
                            text: i18n.t('hide:labels.loading')
                        }
                    },
                    () => {
                        this.forceUpdate();
                        setTimeout(() => {
                            this.setState({
                                    hide: {
                                        preset: 'none',
                                        text: ''
                                    }
                                },
                                () => {
                                    resolve();
                                });
                        }, 0)
                    });
            }
        });
    }

    setHide(newHide = {}){
        if(typeof newHide.preset === 'undefined'
            || typeof newHide.text === 'undefined'){
            newHide = {
                preset: "none",
                text: ""
            };
        }
        this.setState({
            hide: newHide
        });
    }

    render(){
        return (
            <div className="App">
                <ReactNotifications/>
                <HideContainer
                    hide={this.state.hide}
                    selectedNodeName={this.state.selectedNodeName}
                    elements={this.elements}
                />
                <DirectoryContainer
                    lines={this.lines}
                    selectedNodeId={this.state.selectedNodeId}
                    setSelectedNode={this.setSelectedNode}
                    resetSelectedNode={this.resetSelectedNode}
                    files={this.files}
                    addFileOrFolder={this.addFileOrFolder}
                    setFiles={this.setFiles}
                    saveSelectedNode={this.saveSelectedNode}
                    getFileFromStorage={this.getFileFromStorage}
                    setHide={this.setHide}
                    addNotification={this.addNotification}
                    localStorageSafeSetItem={this.localStorageSafeSetItem}
                    elements={this.elements}
                    downloadText={this.downloadText}
                />
                <CodeContainer
                    lines={this.lines}
                    linesCheck={this.state.linesCheck}
                    codeAddLines={this.codeAddLines}
                    removeLines={this.removeLines}
                    changeLine={this.changeLine}
                    selectedNodeName={this.state.selectedNodeName}
                    setVariables={this.setVariables}
                    variables={this.state.variables}
                    setHideLoading={this.setHideLoading}
                />
                <RenderContainer
                    files={this.files}
                    lines={this.lines}
                    variables={this.state.variables}
                    selectedNodeId={this.state.selectedNodeId}
                    getFileFromStorage={this.getFileFromStorage}
                    addNotification={this.addNotification}
                    setHide={this.setHide}
                    downloadText={this.downloadText}
                />
            </div>
        )
    }
}

export default App;
