import React, {Component} from 'react'
import HideComponent from './HideComponent'
import i18n from "i18next";

class RenderContainer extends Component {
    constructor(props){
        super(props);
        let presets = {
            "coderender": {
                width: "calc(82% - 1px)",
                offset: "calc(18% + 1px)",
                display: "block"
            },
            "all": {
                width: "100%",
                offset: "0",
                display: "block"
            },
            "none": {
                width: 0,
                offset: 0,
                display: "none"
            },
        }
        this.state = {
            hide: {
                ...props.hide,
                ...presets[props.hide.preset]
            },
            presets: presets
        };
    }

    static getDerivedStateFromProps(props, state){
        if(props.hide.preset !== 'none'){
            return {
                hide: {
                    ...props.hide,
                    ...state.presets[props.hide.preset]
                }
            };
        }
        else if(props.selectedNodeName === null){
            return {
                hide: {
                    ...state.presets["coderender"],
                    display: "block",
                    text: i18n.t('hide:labels.file not selected')
                }
            };
        }
        return {
            hide: {
                ...state.presets['none'],
                text: ''
            }
        };
    }

    render(){
        return (
            <HideComponent
                hide={this.state.hide}
                elements={this.props.elements}
            />
        )
    }
}

export default RenderContainer