import React from 'react'
import {useTranslation} from 'react-i18next'
import './CodeComponent.css';
import ButtonComponent from "../Button";

function CodeComponent(props){
    const {t} = useTranslation('code');
    return (
        <div className={'CodeComponent Component'}>
            <div className={'title'}>
                {props.selectedNodeName}
            </div>
            {
                props.displayArea === 'code' &&
                <div
                    ref={props.elements.codeArea}
                    className={'CodeArea Area'}
                    onMouseDown={props.handleMouseDown}
                    onMouseUp={props.handleMouseUp}
                    onMouseMove={props.handleMouseMove}
                >
                    <div
                        ref={props.elements.codeTextAreaContainer}
                        className={'codeTextAreaContainer'}
                    >
                    <textarea
                        ref={props.elements.codeTextArea}
                        className={'codeTextArea'}
                        onBlur={props.handleBlur}
                        onKeyDown={props.handleKeyDown}
                        onKeyUp={props.handleKeyUp}
                        onInput={props.handleInput}
                    />
                    </div>
                    <div
                        ref={props.elements.codeLineContainer}
                        className={'codeLineContainer'}
                        tabIndex="-1"
                        onFocus={props.handleFocus}
                    >
                        {/*todo fix THIS TAKES SO MUCH TIMEEEEEE*/}
                        {Object.keys(props.lines).map((key) => {
                            return (
                                <div
                                    className={'codeLine'}
                                    id={'line' + key}
                                    key={props.lines[key].id}
                                    onMouseDown={props.handleLineClick}
                                    dangerouslySetInnerHTML={{__html: props.markText(props.lines[key].text)}}
                                >
                                </div>
                            )
                        })}
                    </div>
                    <div
                        ref={props.elements.cursorContainer}
                        className={'cursorContainer'}
                    >
                        <div
                            ref={props.elements.cursor}
                            className={'cursor'}
                        />
                        <div
                            ref={props.elements.codeCompletion}
                            className={'codeCompletion'}
                        >
                            {props.codeCompletionLines.map((word, key) => {
                                return (
                                    <div
                                        id={'codeCompletionLine' + key}
                                        className={'codeCompletionLine ' + word.className + (props.codeCompletionSelected === key ? ' codeCompletionSelected' : '')}
                                        onMouseDown={props.handleCodeCompletionLineClick}
                                        key={key}
                                    >
                                        {word.word}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div
                        ref={props.elements.highlightContainer}
                        className={'highlightContainer'}
                    />
                </div>
            }
            {
                props.displayArea === 'vars' &&
                <div
                    className={'VarsArea Area'}
                >
                <textarea
                    className={'varsTextArea'}
                    onInput={props.handleVarsInput}
                    onChange={props.handleVarsInput}
                    value={props.variables}
                >
                </textarea>
                </div>
            }
            <ButtonComponent
                displayBigger={true}
                displayChosen={props.displayArea === 'code'}
                onClick={props.handleChangeAreaClick}
                control="code"
                text={t('actions.code')}
            />
            <ButtonComponent
                displayBigger={true}
                displayChosen={props.displayArea === 'vars'}
                onClick={props.handleChangeAreaClick}
                control="vars"
                text={t('actions.variables')}
            />
        </div>
    )
}

export default CodeComponent