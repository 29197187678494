import React from 'react'
import {useTranslation} from "react-i18next";
import './DirectoryNodeComponent.css';
import ButtonComponent from "../Button";

function DirectoryNode(props){
    const {t} = useTranslation('directory');
    return (
        <div
            className={'nodeContainer' + (typeof props.children !== 'undefined' ? ' folder' : '')}
            id={props.id}
        >
            <div
                className={'node'}
                onMouseUp={props.handleClickNode}
                onContextMenu={(e)=>{e.preventDefault();}}
            >
                <div className={'nodePrefix'}>
                    {
                        typeof props.children !== 'undefined' && !props.openedFolderChildren.includes(props.id) &&
                        '+'
                        || typeof props.children !== 'undefined' &&
                        '-'
                    }
                </div>
                <div className={'name'}>{props.name}</div>
                <div
                    className={'controls'}
                    onClick={props.handleControlsClick}
                >
                    <div className={'controlsButton'}>:</div>
                    {
                        props.id === props.openedControlsNodeId &&
                        <div className={'controlsChildren'}
                             style={{top: props.openedControlsTop}}
                        >
                            {
                                typeof props.children !== 'undefined' &&
                                <>
                                    <ButtonComponent
                                        control={'new file'}
                                        text={t('actions.new file')}
                                        displayBorder={true}
                                    />
                                    <ButtonComponent
                                        control={'upload file'}
                                        text={t('actions.upload file')}
                                        displayBorder={true}
                                    />
                                    <ButtonComponent
                                        control={'new folder'}
                                        text={t('actions.new folder')}
                                        displayBorder={true}
                                    />
                                </>
                            }
                            <ButtonComponent
                                control={'delete'}
                                text={t('actions.delete')}
                                displayBorder={true}
                            />
                            <ButtonComponent
                                control={'download'}
                                text={t('actions.download')}
                                displayBorder={true}
                            />
                        </div>
                    }
                </div>
            </div>
            {
                typeof props.children !== 'undefined' && props.openedFolderChildren.includes(props.id) &&
                <div className={'children'}>
                    {props.children.map((id) => {
                        return (<DirectoryNode
                            id={id}
                            key={id}
                            files={props.files}
                            children={props.files[id].children}
                            name={props.files[id].name}
                            handleControlsClick={props.handleControlsClick}
                            handleClickNode={props.handleClickNode}
                            openedControlsNodeId={props.openedControlsNodeId}
                            openedFolderChildren={props.openedFolderChildren}
                            openedControlsTop={props.openedControlsTop}
                        />)
                    })}
                </div>
            }
        </div>
    )
}

export default DirectoryNode