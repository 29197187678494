import React from 'react'
import ButtonComponent from "../Button";
import {useTranslation} from "react-i18next";
import './RenderComponent.css';

function RenderComponent(props){
    const {t} = useTranslation('render');
    return (
        <div className={'RenderComponent Component'}>
            <div className={'title'}>
                {
                    props.renderedName ? t('labels.rendered {what}', {what: props.renderedName}) : t('labels.rendered nothing')
                }
            </div>
            <div className={'RenderArea Area'}>
                {props.renderedText}
            </div>
            <ButtonComponent
                displayBigger={true}
                onClick={props.handleRenderButton}
                text={t('actions.render')}
            />
            <ButtonComponent
                displayBigger={true}
                displayLocked={!props.renderedName && !props.renderedText}
                onClick={props.handleDownloadButton}
                text={t('actions.download render')}
            />
        </div>
    )
}

export default RenderComponent