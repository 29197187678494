import React, {Component} from 'react'
import CodeComponent from './CodeComponent'
import ReactDOMServer from "react-dom/server";

class CodeContainer extends Component {
    constructor(props){
        super(props);
        this.fontHeight = -1;
        this.fontWidth = -1;
        this.focused = false;
        this.cursorInterval = -1;
        this.scrollJumpEnabled = true;
        this.scrollBarSize = 17;
        this.oldText = null;
        this.highlight = {
            enabled: false,
            mouseSelecting: false,
            start: {
                x: -1,
                y: -1
            },
            end: {
                x: -1,
                y: -1
            },
            lines: [],
            lastLine: null,
        };
        this.keysDown = {
            ctrl: false,
            mouse: false,
            shift: false,
        };
        this.elements = {
            codeArea: React.createRef(),
            codeTextArea: React.createRef(),
            codeTextAreaContainer: React.createRef(),
            codeLineContainer: React.createRef(),
            codeCompletion: React.createRef(),
            cursor: React.createRef(),
            cursorContainer: React.createRef(),
            highlightContainer: React.createRef()
        };
        this.selectedLineId = 0;
        this.state = {
            codeCompletionLines: [],
            codeCompletionLinesCheck: 0,
            codeCompletionSelected: 0,
            displayArea: 'code'
        };
        let expressionsBasic = ['gettext', 'ngettext', 'loop'];
        this.jinjaKeywords = {
            expressionsBasic: expressionsBasic,
            statementsBasic: ['block', 'filter', 'raw', 'macro', 'call', 'set', 'trans', 'with', 'autoescape', 'if',
                'for', 'endblock', 'endfilter', 'endraw', 'endmacro', 'endcall', 'endset', 'endtrans', 'endwith',
                'endautoescape', 'endif', 'endfor', 'extends', 'outer_loop', 'else', 'elif', 'continue', 'break',
                'debug', 'false', 'true', 'in', 'is', 'range', 'lipsum', 'dict', 'cycler', 'joiner', 'namespace',
                'pluralize', 'include', 'import', 'from',
                'not', 'and', 'or', 'ignore', 'missing', 'with', 'context', 'as', 'do', 'recursive',
                ...expressionsBasic
            ],
            statementsLoop: ['index', 'index0', 'revindex', 'revindex0', 'first', 'last', 'length', 'cycle',
                'depth', 'depth0', 'previtem', 'nextitem', 'changed'
            ],
            statementsTest: ['boolean', 'even', 'integer', 'ne', 'string', 'callable', 'false', 'iterable',
                'none', 'true', 'defined', 'float', 'le', 'number', 'undefined', 'divisibleby', 'ge', 'lower', 'odd',
                'upper', 'eq', 'gt', 'lt', 'sameas', 'escaped', 'in', 'mapping', 'sequence', 'scoped'
            ],
            filters: ['abs', 'float', 'lower', 'round', 'tojson', 'attr', 'forceescape', 'map',
                'safe', 'trim', 'batch', 'format', 'max', 'select', 'truncate', 'capitalize', 'groupby', 'min',
                'selectattr', 'unique', 'center', 'indent', 'pprint', 'slice', 'upper', 'default', 'int', 'random',
                'sort', 'urlencode', 'dictsort', 'join', 'reject', 'string', 'urlize', 'escape', 'last', 'rejectattr',
                'striptags', 'wordcount', 'filesizeformat', 'length', 'replace', 'sum', 'wordwrap', 'first', 'list',
                'reverse', 'title', 'xmlattr'
            ]
        }
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleLineClick = this.handleLineClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.setHighlight = this.setHighlight.bind(this);
        this.resetHighlight = this.resetHighlight.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.setHighlightAll = this.setHighlightAll.bind(this);
        this.eventSetHighlight = this.eventSetHighlight.bind(this);
        this.handleSelectionStart = this.handleSelectionStart.bind(this);
        this.handleCut = this.handleCut.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.changeCodeCompletionLines = this.changeCodeCompletionLines.bind(this);
        this.handleCodeCompletionLineClick = this.handleCodeCompletionLineClick.bind(this);
        this.getCodeCompletionLines = this.getCodeCompletionLines.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.changeTextAreaValue = this.changeTextAreaValue.bind(this);
        this.handleChangeAreaClick = this.handleChangeAreaClick.bind(this);
        this.setArea = this.setArea.bind(this);
        this.handleVarsInput = this.handleVarsInput.bind(this);
        this.createHighlightLineElements = this.createHighlightLineElements.bind(this);
        this.appendHighlightLines = this.appendHighlightLines.bind(this);
        this.scrollJump = this.scrollJump.bind(this);
        this.keySetHighlight = this.keySetHighlight.bind(this);
        this.keyDownSetCursor = this.keyDownSetCursor.bind(this);
        this.keyDownBackspace = this.keyDownBackspace.bind(this);
        this.keyDownDelete = this.keyDownDelete.bind(this);
        this.keyDownEnter = this.keyDownEnter.bind(this);
        this.keyDownEscape = this.keyDownEscape.bind(this);
        this.keyDownPage = this.keyDownPage.bind(this);
        this.keyDownHomeEnd = this.keyDownHomeEnd.bind(this);
        this.keyDownLeft = this.keyDownLeft.bind(this);
        this.keyDownRight = this.keyDownRight.bind(this);
        this.keyDownUp = this.keyDownUp.bind(this);
        this.keyDownDown = this.keyDownDown.bind(this);
        this.keyDownC = this.keyDownC.bind(this);
        this.getSelectionStart = this.getSelectionStart.bind(this);
        this.changeText = this.changeText.bind(this);
        this.markText = this.markText.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.selectedNodeName !== this.props.selectedNodeName){
            this.setArea('code');
            this.changeCursorPosition(0, 0, this.props.lines[0].text);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContent){
        let linesCheck = this.props.linesCheck !== nextProps.linesCheck;
        let selectedNodeNameCheck = this.props.selectedNodeName !== nextProps.selectedNodeName;
        let variablesCheck = this.props.variables !== nextProps.variables;

        let codeCompletionLinesCheck = this.state.codeCompletionLinesCheck !== nextState.codeCompletionLinesCheck;
        let codeCompletionSelectedCheck = this.state.codeCompletionSelected !== nextState.codeCompletionSelected;
        let displayAreaCheck = this.state.displayArea !== nextState.displayArea;
        return linesCheck || selectedNodeNameCheck || variablesCheck ||
            codeCompletionLinesCheck || codeCompletionSelectedCheck || displayAreaCheck;
    }

    componentDidMount(){
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        context.font = 'normal 18px Courier';
        this.fontHeight = 18;
        this.fontWidth = context.measureText('a').width;

        this.elements.codeCompletion.current.style.top = this.fontHeight + 'px';
        this.elements.codeTextAreaContainer.current.style.top = this.elements.codeTextAreaContainer.current.offsetTop.toString() + 'px';
        this.elements.codeTextArea.current.value = this.props.lines[0].text;
        this.elements.codeTextArea.current.addEventListener("selectstart", () => {
            this.handleSelectionStart();
        })
        this.elements.codeTextArea.current.addEventListener("cut", (event) => {
            this.handleCut(event);
        })
        this.elements.codeTextArea.current.addEventListener("paste", (event) => {
            this.handlePaste(event);
        })
    }

    componentWillUnmount(){
        this.handleBlur();
    }

    handleLineClick(event){
        if(event.button === 2){
            return;
        }
        let codeArea = this.elements.codeArea.current;
        let fontWidth = this.fontWidth;
        let targetElement = event.target;
        if(!targetElement.classList.contains('codeLine') && targetElement.parentElement.classList.contains('codeLine')){
            targetElement = targetElement.parentElement;
        }
        let selectedLineId = parseInt(targetElement.id.match(/\d+/)[0]);
        if(selectedLineId < 0 || this.props.lines.length < selectedLineId){
            return;
        }
        let cursorPositionX = (event.clientX - codeArea.offsetLeft + codeArea.scrollLeft) / fontWidth;
        if(this.highlight.enabled){
            cursorPositionX = Math.ceil(cursorPositionX);
        }
        else {
            cursorPositionX = Math.floor(cursorPositionX);
        }
        this.scrollJumpEnabled = false;
        this.changeCursorPosition(cursorPositionX, selectedLineId, targetElement.innerText, cursorPositionX)
        this.scrollJumpEnabled = true;
    }

    handleSelectionStart(){
        this.setHighlightAll();
    }

    handleCut(event){
        this.copyHighlight();
        this.removeHighlightedText(true);
    }

    handlePaste(event){
        event.preventDefault();
        let codeTextArea = this.elements.codeTextArea.current;
        let clipboardData = event.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        let remHigh = this.removeHighlightedText();
        let newText = remHigh ? remHigh['text'] : codeTextArea.value;
        let nextLine = remHigh ? remHigh['line'] : this.selectedLineId;
        let newSel = remHigh ? remHigh['newSel'] : codeTextArea.selectionEnd;
        let newSelFromRight = newText.length - newSel;
        newText = newText.substr(0, newSel) + pastedData + newText.substr(newSel);
        let [newSelectedLineId, newSelectedLineText] = this.props.codeAddLines(nextLine, newText);
        let selectionEnd = newSelectedLineText.length - newSelFromRight;
        this.changeCursorPosition(selectionEnd, newSelectedLineId, newSelectedLineText,
            selectionEnd, true);
    }

    handleFocus(event){
        if(!this.focused){
            let codeArea = this.elements.codeArea.current;
            let codeTextArea = this.elements.codeTextArea.current;
            let oldScrollLeft = codeArea.scrollLeft;
            let oldScrollTop = codeArea.scrollTop;
            codeTextArea.focus();
            codeArea.scrollLeft = oldScrollLeft;
            codeArea.scrollTop = oldScrollTop;

            let cursorInterval = setInterval(() => {
                let cursor = this.elements.cursor.current;
                if(cursor.style.display === 'block'){
                    cursor.style.display = 'none';
                }
                else {
                    cursor.style.display = 'block';
                }
            }, 600);
            this.focused = true;
            this.cursorInterval = cursorInterval;
        }
    }

    handleInput(){
        let codeTextArea = this.elements.codeTextArea.current;
        let newText = codeTextArea.value;
        //handling changed text
        if(this.oldText !== newText && !this.highlight.enabled){
            let selectedLineId = this.selectedLineId;
            if(newText.includes('\n')){
                let [newSelectedLineId, newSelectedLineText] = this.props.codeAddLines(selectedLineId, newText);
                this.changeCursorPosition(0, newSelectedLineId, newSelectedLineText);
            }
            else {
                this.props.changeLine(selectedLineId, newText);
            }
            this.oldText = codeTextArea.value;
            this.setCursorPosition();

            let writtenWord = this.oldText.substring(0, codeTextArea.selectionEnd).match(/\w+$/);
            if(writtenWord){
                writtenWord = writtenWord[0];
            }
            this.changeCodeCompletionLines(writtenWord);
        }
    }

    handleBlur(){
        clearInterval(this.cursorInterval);
        this.focused = false;
        this.cursorInterval = -1;
        this.elements.cursor.current.style.display = 'block';
    }

    isWordInContext(text, writtenWordIndex, regexContext){
        // checks if writtenWord is in regexContext
        let match;
        while((match = regexContext.exec(text)) != null){
            if(match.index < writtenWordIndex && writtenWordIndex <= (match.index + match[0].length)){
                return true;
            }
        }
        return false;
    }

    addCodeCompletionLines(keywordArray, writtenWord, className){
        // adds all jinja keywords from keywordArray that start with writtenWord
        let resultArr = [];
        if(writtenWord){
            keywordArray.forEach(keyword => {
                if(keyword.indexOf(writtenWord) === 0 && writtenWord.length < keyword.length){
                    resultArr.push({'className': className, 'word': keyword});
                }
            });
        }
        return resultArr;
    }

    getCodeCompletionLines(delimiterArray, writtenWord, type){
        let text = this.elements.codeTextArea.current.value;
        let writtenWordIndex = this.elements.codeTextArea.current.selectionStart - writtenWord.length;
        let match;
        let codeCompletionLines = [];
        // checks if writtenWord is in delimiter, then checks context and gets keywords that start with writtenWord
        if(writtenWord){
            delimiterArray.forEach(delimiter => {
                let regex = new RegExp(`${delimiter.prefix}\\s*.*?\\s*${delimiter.postfix}`, 'g');
                while((match = regex.exec(text)) != null){
                    if(match.index < writtenWordIndex && writtenWordIndex <= (match.index + match[0].length)){
                        let writtenWordIndexInMatch = writtenWordIndex - match.index;
                        //add filters keywords
                        if((type === 'expression' || type === 'statement')
                            && this.isWordInContext(match[0], writtenWordIndexInMatch, new RegExp('\\|\\W*\\w+', 'g'))){
                            codeCompletionLines.push(...this.addCodeCompletionLines(this.jinjaKeywords.filters,
                                writtenWord, 'markYellow'));
                        }
                        //add loop keywords
                        else if((type === 'expression' || type === 'statement')
                            && this.isWordInContext(match[0], writtenWordIndexInMatch, new RegExp('loop\\.\\w+', 'g'))){
                            codeCompletionLines.push(...this.addCodeCompletionLines(this.jinjaKeywords.statementsLoop,
                                writtenWord, 'markYellow'));
                        }
                        //add tests keywords
                        else if(type === 'statement'
                            && this.isWordInContext(match[0], writtenWordIndexInMatch, new RegExp('\\W+is\\W+\\w+', 'g'))){
                            codeCompletionLines.push(...this.addCodeCompletionLines(this.jinjaKeywords.statementsTest,
                                writtenWord, 'markOrange'));
                        }
                        //add all statement/expression keywords that dont need context
                        else if(type === 'expression' || type === 'statement'){
                            codeCompletionLines.push(...this.addCodeCompletionLines(
                                type === 'statement' ?
                                    this.jinjaKeywords.statementsBasic :
                                    this.jinjaKeywords.expressionsBasic,
                                writtenWord, 'markOrange'));
                        }
                        return codeCompletionLines;
                    }
                }
            })
        }
        return codeCompletionLines;
    }

    changeCodeCompletionLines(writtenWord){
        let codeCompletionLines = [];
        if(writtenWord){
            codeCompletionLines = [
                ...this.getCodeCompletionLines([{'prefix': '{%', 'postfix': '%}'}, {'prefix': '#', 'postfix': '##'}],
                    writtenWord, 'statement'),
                ...this.getCodeCompletionLines([{'prefix': '{{', 'postfix': '}}'}],
                    writtenWord, 'expression')];
        }
        this.setState(function(prevState){
            return {
                ...prevState,
                codeCompletionLines: codeCompletionLines,
                codeCompletionLinesCheck: prevState.codeCompletionLinesCheck + 1,
                codeCompletionSelected: 0
            }
        })
    }

    handleCodeCompletionLineClick(event){
        let completedWord = event.target.innerText;
        let selectionEnd = this.elements.codeTextArea.current.selectionEnd;
        let writtenWord = this.oldText.substring(0, selectionEnd).match(/\w+$/);
        if(writtenWord){
            writtenWord = writtenWord[0];
            let newText = this.oldText.substring(0, selectionEnd - writtenWord.length) + completedWord;
            newText += this.oldText.substring(selectionEnd);
            let selectedLineId = this.selectedLineId;
            this.props.changeLine(selectedLineId, newText);
            this.changeCursorPosition(selectionEnd - writtenWord.length + completedWord.length,
                selectedLineId, newText);
            this.changeCodeCompletionLines(null);
        }
    }

    handleKeyDown(event){
        // todo handle ctrl+left/right
        // todo handle tab
        // todo handle select text by keyboard
        //      shift + ctrl + [arrow right/left]
        let keyCode = event.which || event.keyCode;
        switch(keyCode){
            default:{
                if(this.highlight.enabled){
                    this.removeHighlightedText(true);
                }
                break;
            }
            case 8:{ //backspace
                this.keyDownBackspace(event);
                break;
            }
            case 13:{//enter
                this.keyDownEnter(event);
                break;
            }
            case 16:{ //shift
                this.keysDown.shift = true;
                break;
            }
            case 17:{ //ctrl
                this.keysDown.ctrl = true;
                break;
            }
            case 27:{ //escape
                this.keyDownEscape(event);
                break;
            }
            case 33:{ //page up
                this.keyDownPage(event, true);
                break;
            }
            case 34:{ //page down
                this.keyDownPage(event, false);
                break;
            }
            case 35:{ //end
                this.keyDownHomeEnd(event, false);
                break;
            }
            case 36:{ //home
                this.keyDownHomeEnd(event, true);
                break;
            }
            case 37:{ //left arrow
                this.keyDownLeft(event);
                break;
            }
            case 38:{ //up arrow
                this.keyDownUp(event);
                break;
            }
            case 39:{ //right arrow
                this.keyDownRight(event);
                break;
            }
            case 40:{ //down arrow
                this.keyDownDown(event);
                break;
            }
            case 46:{ //delete
                this.keyDownDelete(event);
                break;
            }
            case 67:{ //C
                this.keyDownC();
                break;
            }
            case 65:{ //A
                this.keyDownA();
                break;
            }
            case 86:  //V
            case 88:{ //X
                this.keyDownControlCombinations();
                break;
            }
        }
    }

    handleKeyUp(event){
        let keyCode = event.which || event.keyCode;
        /*
        16: shift
        17: ctrl
         */
        if([16, 17].includes(keyCode)){
            event.preventDefault();
            switch(keyCode){
                default:
                    break;
                case 16:{ //shift
                    this.keysDown.shift = false;
                    break;
                }
                case 17:{ //ctrl
                    this.keysDown.ctrl = false;
                    break;
                }
            }
        }
    }

    keyDownSetCursor(lineId, text, selectionStart = this.getSelectionStart()){
        this.changeCodeCompletionLines(null);
        if(text.length < selectionStart){
            selectionStart = text.length;
        }
        if(this.keysDown.shift){
            this.keySetHighlight(selectionStart * this.fontWidth, lineId)
        }
        else if(this.highlight.enabled){
            this.resetHighlight();
        }
        if(!this.highlight.enabled){
            this.changeCursorPosition(selectionStart, lineId, text, selectionStart, true);
        }
    }

    keyDownBackspace(event){
        if(this.highlight.enabled){
            event.preventDefault();
            this.removeHighlightedText(true);
        }
        else if(this.elements.codeTextArea.current.selectionStart === 0
            && this.selectedLineId !== 0){
            //if cursor is at the start of line and there is another line above selected
            //   move to above line, add old line text to above line text, set cursor position, remove old line
            event.preventDefault();
            let selectedLineId = this.selectedLineId;
            let aboveLineId = this.selectedLineId - 1;

            //change oldText so 'handling changed text' will get triggered
            if(!this.props.lines[aboveLineId].text){
                this.oldText = this.props.lines[aboveLineId].text;
            }
            this.changeCursorPosition(this.props.lines[aboveLineId].text.length,
                aboveLineId,
                this.props.lines[aboveLineId].text + this.props.lines[selectedLineId].text);
            this.props.removeLines(selectedLineId, 1);
        }
    }

    keyDownDelete(event){
        if(this.highlight.enabled){
            event.preventDefault();
            this.removeHighlightedText(true);
        }
        else if(this.elements.codeTextArea.current.selectionStart === this.props.lines[this.selectedLineId].text.length
            && this.selectedLineId !== this.props.lines.length - 1){
            //if cursor is at the end of line and there is another line below selected
            //   add below line text to old line text, set deleteLine to below line
            event.preventDefault();
            let selectedLineId = this.selectedLineId;
            let belowLineId = this.selectedLineId + 1;

            //change text only if below line is not empty
            if(this.props.lines[belowLineId].text){
                this.changeCursorPosition(this.props.lines[selectedLineId].text.length,
                    selectedLineId,
                    this.props.lines[selectedLineId].text + this.props.lines[belowLineId].text);
            }
            this.props.removeLines(belowLineId, 1);
        }
    }

    keyDownEnter(event){
        if(this.state.codeCompletionLines.length){
            event.preventDefault();
            event.target = this.elements.codeCompletion.current.children[this.state.codeCompletionSelected];
            this.handleCodeCompletionLineClick(event);
        }
        else if(this.highlight.enabled){
            this.removeHighlightedText(true);
        }
    }

    keyDownEscape(event){
        if(this.state.codeCompletionLines.length){
            event.preventDefault();
            this.changeCodeCompletionLines(null);
        }
        else if(this.highlight.enabled){
            this.removeHighlightedText(true);
        }
    }

    keyDownPage(event, pageUp){
        event.preventDefault();
        let i = 0;
        let newLineId = this.selectedLineId;
        let shiftDown = this.keysDown.shift;
        let skipBy = Math.floor(this.elements.codeArea.current.offsetHeight / this.fontHeight);
        let selectionStart = this.getSelectionStart();
        //go to above line until there isn't one or we have already went by skipBy lines
        while(newLineId !== (pageUp ? 0 : this.props.lines.length - 1) && i < skipBy){
            pageUp ? newLineId-- : newLineId++;
            i++;
            if(shiftDown){
                this.keySetHighlight(0, newLineId)
            }
        }
        if(i < skipBy){
            selectionStart = pageUp ? 0 : this.props.lines[newLineId].text.length;
        }
        this.keyDownSetCursor(newLineId, this.props.lines[newLineId].text, selectionStart);
    }

    keyDownHomeEnd(event, home){
        event.preventDefault();
        let newSelection = home ? 0 : this.props.lines[this.selectedLineId].text.length;
        this.keyDownSetCursor(this.selectedLineId, this.props.lines[this.selectedLineId].text, newSelection);
    }

    keyDownLeft(event){
        event.preventDefault();
        let newSelection = this.getSelectionStart();
        let newLineId = this.selectedLineId;

        //if cursor is at the start of line and there is another line above selected
        //   move to above line and set cursor to end of line
        //else move cursor to left
        if(newSelection === 0 && newLineId !== 0){
            newLineId--;
            newSelection = this.props.lines[newLineId].text.length;
        }
        else if(newSelection !== 0){
            newSelection--;
        }
        this.keyDownSetCursor(newLineId, this.props.lines[newLineId].text, newSelection);
    }

    keyDownRight(event){
        event.preventDefault();
        let newSelection = this.getSelectionStart();
        let newLineId = this.selectedLineId;
        //if cursor is at the end of line and there is another line below selected
        //   move to below line and set cursor to start of line
        //else move cursor to right
        let innerTextLength = this.props.lines[newLineId].text.length;
        if(newSelection === innerTextLength && newLineId !== this.props.lines.length - 1){
            newSelection = 0;
            newLineId++;
        }
        else if(newSelection !== innerTextLength){
            newSelection++;
        }
        this.keyDownSetCursor(newLineId, this.props.lines[newLineId].text, newSelection);
    }

    keyDownUp(event){
        event.preventDefault();
        if(this.state.codeCompletionLines.length){
            let codeCompletionSelected = this.state.codeCompletionSelected;
            if(0 <= codeCompletionSelected - 1){
                codeCompletionSelected--;
            }
            else {
                codeCompletionSelected = this.state.codeCompletionLines.length - 1;
            }
            this.setState(function(prevState){
                return {
                    ...prevState,
                    codeCompletionSelected: codeCompletionSelected
                }
            })
        }
        else {
            let newLineId = this.selectedLineId;
            let selectionStart = this.getSelectionStart();
            //if there is another line above selected
            ///   move to above line
            if(newLineId !== 0){
                newLineId--;
            }
            else {
                selectionStart = 0;
            }
            this.keyDownSetCursor(newLineId, this.props.lines[newLineId].text, selectionStart);
        }
    }

    keyDownDown(event){
        event.preventDefault();
        if(this.state.codeCompletionLines.length){
            let codeCompletionSelected = this.state.codeCompletionSelected;
            if(codeCompletionSelected + 1 < this.state.codeCompletionLines.length){
                codeCompletionSelected++;
            }
            else {
                codeCompletionSelected = 0;
            }
            this.setState(function(prevState){
                return {
                    ...prevState,
                    codeCompletionSelected: codeCompletionSelected
                }
            })
        }
        else {
            let newLineId = this.selectedLineId;
            let selectionStart = this.getSelectionStart();
            //if there is another line below selected
            ///   move to below line
            if(newLineId !== this.props.lines.length - 1){
                newLineId++;
            }
            else {
                selectionStart = this.props.lines[newLineId].text.length;
            }
            this.keyDownSetCursor(newLineId, this.props.lines[newLineId].text, selectionStart);
        }
    }

    keyDownC(){
        if(this.highlight.enabled && this.keysDown.ctrl){
            this.copyHighlight();
        }
        this.keyDownControlCombinations();
    }

    keyDownA(){
        if(!this.highlight.enabled && this.keysDown.ctrl){
            this.handleSelectionStart();
        }
        this.keyDownControlCombinations();
    }

    keyDownControlCombinations(){
        if(this.highlight.enabled && !this.keysDown.ctrl){
            this.removeHighlightedText(true);
        }
    }

    getSelectionStart(){
        if(this.highlight.enabled){
            return this.getCharCount(this.highlight.end.x, this.isHighlightSwitched());
        }
        else {
            return this.elements.codeTextArea.current.selectionStart;
        }
    }

    getCharCount(length, floor){
        let fontWidth = this.fontWidth;
        if(floor){
            return Math.floor(length / fontWidth);
        }
        return Math.ceil(length / fontWidth);
    }

    getLineCount(length, floor){
        let fontHeight = this.fontHeight;
        if(floor){
            return Math.floor(length / fontHeight);
        }
        return Math.ceil(length / fontHeight);
    }


    handleMouseDown(event){
        //empty codeCompletionLines
        if(this.state.codeCompletionLines.length){
            this.changeCodeCompletionLines(null);
        }
        if(event.button === 2){
            return;
        }
        let target = event.target;
        let codeArea = this.elements.codeArea.current;
        let scrollBarSize = this.scrollBarSize;
        let scrollJumpEnabled = true;

        //x scrollbar
        let clientYmax = codeArea.offsetHeight + codeArea.offsetTop;
        let clientYmin = clientYmax - scrollBarSize;
        let clientY = event.clientY;
        //y scrollbar
        let clientXmax = codeArea.offsetWidth + codeArea.offsetLeft;
        let clientXmin = clientXmax - scrollBarSize;
        let clientX = event.clientX;

        if((clientYmin <= clientY && clientY <= clientYmax)
            || (clientXmin <= clientX && clientX <= clientXmax)){
            scrollJumpEnabled = false;
        }
        else if(target.classList.contains('codeLine') || target.parentElement.classList.contains('codeLine')){
            if(target.parentElement.classList.contains('codeLine')){
                target = target.parentElement;
            }
            if(this.highlight.enabled){
                this.resetHighlight();
            }
            let highlightStartX = clientX - codeArea.offsetLeft + codeArea.scrollLeft;
            if(target.innerText.length < this.getCharCount(highlightStartX, false)){
                highlightStartX = target.innerText.length * this.fontWidth;
            }
            let highlightStartY = clientY - codeArea.offsetTop + codeArea.scrollTop;
            this.highlight.enabled = true;
            this.highlight.mouseSelecting = true;
            this.highlight.start.x = highlightStartX;
            this.highlight.start.y = highlightStartY;
            this.highlight.end.x = highlightStartX;
            this.highlight.end.y = highlightStartY;
            this.highlight.lines = [];
            this.highlight.lastLine = null;
        }
        this.keysDown.mouse = true;
        this.scrollJumpEnabled = scrollJumpEnabled;
    }

    handleMouseUp(event){
        if(event.button === 2){
            let codeTextAreaContainer = this.elements.codeTextAreaContainer.current;
            let codeArea = this.elements.codeArea.current;
            let codeTextArea = this.elements.codeTextArea.current;
            let clientX = event.clientX;
            let clientY = event.clientY;
            let oldTop = codeTextAreaContainer.style.top;
            codeTextAreaContainer.style.top = (clientY - (codeTextArea.offsetHeight / 2) - codeArea.offsetTop + codeArea.scrollTop).toString() + 'px';
            codeTextAreaContainer.style.left = (clientX - (codeTextArea.offsetWidth / 2) - codeArea.offsetLeft + codeArea.scrollLeft).toString() + 'px';
            codeTextAreaContainer.style.zIndex = '10';
            setTimeout(function(codeTextAreaContainer, oldTop, selStart, selEnd){
                codeTextAreaContainer.style.top = oldTop;
                codeTextAreaContainer.style.left = '0px';
                codeTextArea.selectionStart = selStart;
                codeTextArea.selectionEnd = selEnd;
                codeTextAreaContainer.style.zIndex = '-10';
            }, 10, codeTextAreaContainer, oldTop, codeTextArea.selectionStart, codeTextArea.selectionEnd);
        }
        else {
            if(!this.scrollJumpEnabled){
                this.scrollJumpEnabled = true;
            }

            this.keysDown.mouse = false;
            if(this.highlight.mouseSelecting){
                this.eventSetHighlight(event.target, event.clientX, event.clientY);
                this.highlight.mouseSelecting = false;
            }
            this.elements.codeLineContainer.current.focus();
        }
    }

    handleMouseMove(event){
        if(this.keysDown.mouse && this.highlight.mouseSelecting){
            this.eventSetHighlight(event.target, event.clientX, event.clientY);
        }
        if(event.target.classList.contains('codeCompletionLine') && !event.target.classList.contains('codeCompletionSelected')){
            let codeCompletionSelected = parseInt(event.target.id.match(/\d+/)[0]);
            this.setState(function(prevState){
                return {
                    ...prevState,
                    codeCompletionSelected: codeCompletionSelected
                }
            })
        }
    }

    eventSetHighlight(target, clientX, clientY){
        if(target.parentElement.classList.contains('codeLine')){
            target = target.parentElement;
        }
        if(target.classList.contains('codeLine')){
            let codeArea = this.elements.codeArea.current;
            let highlightEndX = clientX - codeArea.offsetLeft + codeArea.scrollLeft;
            if(target.innerText.length < this.getCharCount(highlightEndX, false)){
                highlightEndX = target.innerText.length * this.fontWidth;
            }
            let highlightEndY = clientY - codeArea.offsetTop + codeArea.scrollTop;
            this.setHighlight(highlightEndX, highlightEndY, parseInt(target.id.match(/\d+/)[0]));
        }
    }

    keySetHighlight(highlightEndX, targetId){
        let fontHeight = this.fontHeight;
        let highlightEndY = (targetId + 0.5) * fontHeight;
        if(!this.highlight.enabled){
            let codeTextArea = this.elements.codeTextArea.current;
            let cursorContainer = this.elements.cursorContainer.current;
            let highlightStartX = codeTextArea.selectionStart * this.fontWidth;
            let highlightStartY = parseInt(cursorContainer.style.top.replace('px', ''));
            highlightStartY += fontHeight * 0.5;

            let targetStartId = this.selectedLineId;
            this.highlight.enabled = true;
            this.highlight.start.x = highlightStartX;
            this.highlight.start.y = highlightStartY;
            this.highlight.end.x = highlightEndX;
            this.highlight.end.y = highlightEndY;
            this.highlight.lines = [targetStartId];
            this.highlight.lastLine = targetStartId;
        }
        this.setHighlight(highlightEndX, highlightEndY, targetId);
    }

    createHighlightLineElements(highlightStartX, highlightEndX, highlightStartY, highlightEndY){
        let highlightContainer = this.elements.highlightContainer.current;
        let codeLineContainerOffsetWidth = this.elements.codeLineContainer.current.offsetWidth;
        let fontHeight = this.fontHeight;
        //for every line in highlight
        //  create highlight div and append it to highlightContainer
        let heightDiff = Math.abs(highlightEndY - highlightStartY);
        highlightContainer.innerHTML = '';
        for(let i = 0; i < (heightDiff / fontHeight); i++){
            let newHighlightFirst = document.createElement('div');
            newHighlightFirst.classList.add('highlight');
            newHighlightFirst.style.top = (highlightStartY + i * fontHeight) + 'px';
            newHighlightFirst.style.height = fontHeight + 'px';

            let offset = 0;
            if(i === 0){
                newHighlightFirst.style.left = highlightStartX + 'px';
            }
            else {
                newHighlightFirst.style.left = 0 + 'px';
                offset = highlightStartX;
            }

            if(i === (heightDiff / fontHeight) - 1){
                newHighlightFirst.style.width = (codeLineContainerOffsetWidth < (highlightEndX - highlightStartX + offset) ? codeLineContainerOffsetWidth : (highlightEndX - highlightStartX + offset)) + 'px';
            }
            else if(i === 0){
                newHighlightFirst.style.width = (0 < (codeLineContainerOffsetWidth - highlightStartX) ? (codeLineContainerOffsetWidth - highlightStartX) : 0) + 'px';
            }
            else {
                newHighlightFirst.style.width = codeLineContainerOffsetWidth + 'px';
            }

            highlightContainer.appendChild(newHighlightFirst);
        }
    }

    appendHighlightLines(highlightStartX, highlightEndX, originalEndY, highlightTargetId){
        if(highlightTargetId === null || highlightTargetId < 0 || this.props.lines.length <= highlightTargetId){
            return;
        }
        //adding highlighted lines for future getting highlighted text
        //checking if endY is really on the target
        let fontHeight = this.fontHeight;
        let targetTop = highlightTargetId * fontHeight;
        if(targetTop < originalEndY && originalEndY < targetTop + fontHeight){
            let codeTextArea = this.elements.codeTextArea.current;
            let highlightLines = this.highlight.lines;
            if(!highlightLines.includes(highlightTargetId)){
                if(!highlightLines.length){
                    this.changeTextAreaValue(this.props.lines[highlightTargetId].text);
                    highlightLines.push(highlightTargetId);
                }
                else if(this.highlight.lastLine < highlightTargetId){
                    this.changeTextAreaValue(codeTextArea.value + '\n' + this.props.lines[highlightTargetId].text);
                    highlightLines.push(highlightTargetId);
                }
                else {
                    this.changeTextAreaValue(this.props.lines[highlightTargetId].text + '\n' + codeTextArea.value);
                    highlightLines.unshift(highlightTargetId);
                }
            }
            else if(this.highlight.lastLine === highlightTargetId - 1){
                highlightLines.shift();
                this.changeTextAreaValue(codeTextArea.value.substring(codeTextArea.value.indexOf("\n") + 1));
            }
            else if(this.highlight.lastLine === highlightTargetId + 1){
                highlightLines.pop();
                this.changeTextAreaValue(codeTextArea.value.substring(codeTextArea.value.lastIndexOf("\n"), -1));
            }
            this.highlight.lines = highlightLines;
            this.highlight.lastLine = highlightTargetId;

            //change cursor position
            let fontWidth = this.fontWidth;
            let newSelectionStart = highlightStartX / fontWidth;
            let newSelectionEnd = highlightEndX / fontWidth;
            newSelectionEnd += codeTextArea.value.length - codeTextArea.value.split(/\n/).pop().length;
            this.changeCursorPosition(newSelectionEnd, highlightTargetId, undefined, newSelectionStart);
        }
    }

    setHighlight(highlightEndX, highlightEndY, highlightTargetId = null){
        let highlightStartX = this.highlight.start.x;
        let highlightStartY = this.highlight.start.y;
        let originalEndY = highlightEndY;
        this.highlight.end.x = highlightEndX;
        this.highlight.end.y = highlightEndY;
        //if mouse moved by more than 2 pixel in any direction
        if(Math.abs(highlightStartX - highlightEndX) > 2 || Math.abs(highlightStartY - highlightEndY) > 2){
            let fontHeight = this.fontHeight;
            let fontWidth = this.fontWidth;
            //if highlight is on one line but start is more to the right than highlightEnd
            // or if highlightEnd line is above start line
            //  switch x and y position of highlight
            //else if highlight is on the same line and start position is above highlightEnd position
            //  switch y position of highlight
            if(this.isHighlightSwitched()){
                let tmp = highlightStartX;
                highlightStartX = highlightEndX;
                highlightEndX = tmp;
                tmp = highlightStartY;
                highlightStartY = highlightEndY;
                highlightEndY = tmp;
            }

            //set highlight positions to match character position
            highlightStartX = this.getCharCount(highlightStartX, true) * fontWidth;
            highlightEndX = this.getCharCount(highlightEndX, false) * fontWidth;
            highlightStartY = this.getLineCount(highlightStartY, true) * fontHeight;
            highlightEndY = this.getLineCount(highlightEndY, false) * fontHeight;

            this.createHighlightLineElements(highlightStartX, highlightEndX, highlightStartY, highlightEndY);
            this.appendHighlightLines(highlightStartX, highlightEndX, originalEndY, highlightTargetId);
        }
        else {
            this.elements.highlightContainer.current.innerHTML = '';
            if(!this.keysDown.mouse && this.highlight.enabled){
                this.resetHighlight();
            }
        }
    }

    setHighlightAll(){
        let fontHeight = this.fontHeight;
        let fontWidth = this.fontWidth;

        let nextLineId = this.props.lines.length - 1;
        let newText = '';

        let highlightEndX = this.props.lines[nextLineId].text.length * fontWidth;
        let highlightEndY = nextLineId * fontHeight + fontHeight;
        let highlightLines = [];
        this.highlight.enabled = true;
        this.highlight.start.x = 0;
        this.highlight.start.y = 0;
        this.highlight.lastLine = this.props.lines.length - 1;
        this.props.setHideLoading(this.props.lines.length > 2500).then(() => {
            this.props.lines.forEach((text, key) => {
                highlightLines.push(key);
                newText += text.text + '\n';
            })
            newText = newText.slice(0, -1);
            this.highlight.lines = highlightLines;
            this.setHighlight(highlightEndX, highlightEndY);
            this.changeCursorPosition(newText.length, nextLineId, newText, 0);
        });
    }

    resetHighlight(){
        this.highlight.enabled = false;
        this.highlight.start.x = -1;
        this.highlight.start.y = -1;
        this.highlight.end.x = -1;
        this.highlight.end.y = -1;
        this.highlight.lines = [];
        this.highlight.lastLine = null;
        this.elements.highlightContainer.current.innerHTML = '';
    }

    //todo add comments
    removeHighlightedText(changeCursorPosition = false){
        let highlightLines = this.highlight.lines;
        if(highlightLines.length){
            let highlightStartX = this.highlight.start.x;
            let highlightEndX = this.highlight.end.x;
            if(this.isHighlightSwitched()){
                let tmp = highlightStartX;
                highlightStartX = highlightEndX;
                highlightEndX = tmp;
            }
            highlightStartX = this.getCharCount(highlightStartX, true);
            highlightEndX = this.getCharCount(highlightEndX, false);

            let text = '';
            let firstLineId = highlightLines[0];
            if(highlightLines.length === 1){
                let innerText = this.props.lines[firstLineId].text;
                text = innerText.substring(0, highlightStartX);
                if(highlightEndX < innerText.length){
                    text += innerText.substring(highlightEndX, innerText.length);
                }
            }
            else {
                text = this.props.lines[firstLineId].text.substring(0, highlightStartX);
                let lastLineText = this.props.lines[highlightLines[highlightLines.length - 1]].text;
                if(highlightEndX < lastLineText.length){
                    text += lastLineText.substring(highlightEndX, lastLineText.length);
                }
                this.props.removeLines(highlightLines[1], highlightLines.length - 1);
            }
            this.resetHighlight();
            if(changeCursorPosition){
                this.changeCursorPosition(highlightStartX, firstLineId, text);
                return false;
            }
            else {
                return {
                    'text': text,
                    'line': firstLineId,
                    'newSel': highlightStartX
                };
            }
        }
        return false;
    }

    copyHighlight(){
        document.execCommand('copy');
        setTimeout(() => {
            this.handleFocus();
        }, 0);
    }

    isHighlightSwitched(){
        if(!this.highlight.enabled){
            return false;
        }
        let highlightStartX = this.highlight.start.x;
        let highlightStartY = this.highlight.start.y;
        let highlightEndX = this.highlight.end.x;
        let highlightEndY = this.highlight.end.y;
        //if highlight is on one line but start is more to the right than highlightEnd
        // or if highlightEnd line is above start line
        //  switch x and y position of highlight
        //else if highlight is on the same line and start position is above highlightEnd position
        //  switch y position of highlight
        return (this.getLineCount(highlightStartY, true) === this.getLineCount(highlightEndY, true) && (highlightStartX > highlightEndX))
            || this.getLineCount(highlightStartY, true) > this.getLineCount(highlightEndY, true);
    }

    changeTextAreaValue(newVal, selectionEnd = null, selectionStart = selectionEnd,
                        setOldText = false){
        if(newVal !== undefined){
            this.elements.codeTextArea.current.value = newVal;
            if(setOldText){
                this.oldText = newVal;
            }
        }
        if(selectionEnd !== null){
            this.elements.codeTextArea.current.selectionStart = selectionStart;
            this.elements.codeTextArea.current.selectionEnd = selectionEnd;
        }
        this.handleInput();
    }

    changeCursorPosition(selectionEnd, selectedLineId, textAreaValue = undefined,
                         selectionStart = selectionEnd, setOldText = false){
        if(selectedLineId < 0 || this.props.lines.length < selectedLineId){
            return;
        }
        this.selectedLineId = selectedLineId;
        this.elements.codeTextAreaContainer.current.style.top = (selectedLineId * this.fontHeight).toString() + 'px';
        this.changeTextAreaValue(textAreaValue, selectionEnd, selectionStart, setOldText);
        this.setCursorPosition();
    }

    setCursorPosition(){
        let cursorContainer = this.elements.cursorContainer.current;
        let codeTextArea = this.elements.codeTextArea.current;
        let codeArea = this.elements.codeArea.current;
        let fontWidth = this.fontWidth;

        cursorContainer.style.top = codeTextArea.parentElement.offsetTop.toString() + 'px';
        let cursorLeft;
        if(this.highlight.enabled && !this.isHighlightSwitched()){
            cursorLeft = codeTextArea.selectionEnd - codeTextArea.value.length + codeTextArea.value.split(/\n/).pop().length;
        }
        else {
            cursorLeft = codeTextArea.selectionStart;
        }
        cursorContainer.style.left = (cursorLeft * fontWidth).toString() + 'px';
        codeTextArea.style.width = (codeTextArea.value.length * fontWidth + 100).toString() + 'px';
        if(this.scrollJumpEnabled){
            codeArea.scrollLeft = this.scrollJump(this.fontWidth * 2, codeArea.offsetWidth,
                parseInt(cursorContainer.style.left) - codeArea.scrollLeft + 17 + this.fontWidth * 2, codeArea.scrollLeft);
            codeArea.scrollTop = this.scrollJump(this.fontHeight * 2, codeArea.offsetHeight,
                parseInt(cursorContainer.style.top) - codeArea.scrollTop + 17 + this.fontHeight * 2, codeArea.scrollTop);
        }
    }

    scrollJump(jumpSize, maxPosition, nowPosition, scroll){
        //adds offset to the right or bottom when writing
        let scrollBarWidth = 17;

        //if cursor is out of codeArea
        //  scroll by scrollJump
        //  if cursor if further than (codeArea + scrollJump) then also scroll by the difference
        if(maxPosition <= nowPosition){
            scroll += jumpSize;
            if(maxPosition + jumpSize < nowPosition){
                scroll += nowPosition - maxPosition;
            }
        }
        else if(nowPosition - scrollBarWidth - jumpSize <= 0){
            scroll -= 2 * jumpSize;
            if(nowPosition - scrollBarWidth - jumpSize <= -2 * jumpSize){
                scroll -= -nowPosition + scrollBarWidth + jumpSize;
            }
        }
        return scroll;
    }

    setArea(newDisplayArea){
        if(newDisplayArea === 'code' || newDisplayArea === 'vars'){
            this.setState(function(prevState){
                return {
                    ...prevState,
                    displayArea: newDisplayArea
                }
            })
        }
    }

    handleChangeAreaClick(event){
        this.setArea(event.target.getAttribute('control'));
    }

    handleVarsInput(event){
        this.props.setVariables(event.target.value);
    }

    markJinjaKeywords(jinjaLine, checkArr, classMark, regex, replaceRegex = null){
        let matchedWords = jinjaLine.inner.match(regex);
        if(matchedWords !== null && matchedWords.length){
            if(replaceRegex){
                matchedWords = matchedWords.map(elem => {
                    return elem.replace(replaceRegex, `$2`);
                });
            }
            (new Set(matchedWords)).forEach(jinjaKeyword => {
                if(checkArr.includes(jinjaKeyword)){
                    jinjaLine.new = jinjaLine.new.replace(new RegExp(`(\\W)(${jinjaKeyword})(\\W)`, 'g'), `$1<span class="${classMark}">${jinjaKeyword}</span>$3`);
                }
            })
        }
        return jinjaLine;
    }

    changeText(text, type, surroundArr){
        let jinjaLines = [];
        surroundArr.forEach(elem => {
            jinjaLines.push(...(text.match(new RegExp(`${elem.prefix}\\s*(.*?)\\s*${elem.postfix}`, 'g')) ?? []));
        });
        if(jinjaLines.length){
            jinjaLines = jinjaLines.map(function(jinjaLine){
                let inner = jinjaLine;
                surroundArr.forEach(elem => {
                    inner = inner.replace(new RegExp(`^${elem.prefix}\\s*|\\s*${elem.postfix}$`, 'g'), '');
                });
                return {
                    'original': jinjaLine,
                    'inner': inner,
                    'new': jinjaLine
                };
            });
            jinjaLines.forEach(jinjaLine => {
                if(type === 'statement' || type === 'expression'){
                    //mark all statement/expression keywords that dont need context
                    jinjaLine = this.markJinjaKeywords(jinjaLine,
                        type === 'statement' ? this.jinjaKeywords.statementsBasic : this.jinjaKeywords.expressionsBasic,
                        'markOrange', new RegExp('(\\w+)', 'g'));

                    //mark filters keywords
                    jinjaLine = this.markJinjaKeywords(jinjaLine, this.jinjaKeywords.filters, 'markYellow',
                        new RegExp('(\\|\\W*)(\\w+)', 'g'), new RegExp('(\\|\\W*)(\\w+)'));

                    //mark loop keywords
                    jinjaLine = this.markJinjaKeywords(jinjaLine, this.jinjaKeywords.statementsLoop, 'markYellow',
                        new RegExp('loop\\.\\w+', 'g'), new RegExp('(loop\\.)(\\w+)'));
                }
                if(type === 'statement'){
                    //mark tests keywords
                    jinjaLine = this.markJinjaKeywords(jinjaLine, this.jinjaKeywords.statementsTest, 'markOrange',
                        new RegExp('(\\W+is\\W+)(\\w+)', 'g'), new RegExp('(\\W+is\\W+)(\\w+)'));
                }
                if(type === 'comment'){
                    jinjaLine.new = jinjaLine.new.replace(new RegExp(`(${jinjaLine.inner})`), `<span class="markGray">${jinjaLine.inner}</span>`);
                }
                text = text.split(jinjaLine.original).join(jinjaLine.new);
            });
        }
        return text;
    }

    markText(text){
        text = ReactDOMServer.renderToString(text);
        text = this.changeText(text, 'statement', [{'prefix': '{%', 'postfix': '%}'},
            {'prefix': '#', 'postfix': '##'}]);
        text = this.changeText(text, 'expression', [{'prefix': '{{', 'postfix': '}}'}]);
        text = this.changeText(text, 'comment', [{'prefix': '{#', 'postfix': '#}'}]);
        return text;
    }

    render(){
        return (
            <CodeComponent
                handleFocus={this.handleFocus}
                handleBlur={this.handleBlur}
                handleKeyDown={this.handleKeyDown}
                handleKeyUp={this.handleKeyUp}
                handleLineClick={this.handleLineClick}
                handleMouseDown={this.handleMouseDown}
                handleMouseUp={this.handleMouseUp}
                handleMouseMove={this.handleMouseMove}
                lines={this.props.lines}
                selectedNodeName={this.props.selectedNodeName}
                codeCompletionLines={this.state.codeCompletionLines}
                codeCompletionSelected={this.state.codeCompletionSelected}
                handleCodeCompletionLineClick={this.handleCodeCompletionLineClick}
                markText={this.markText}
                handleInput={this.handleInput}
                handleChangeAreaClick={this.handleChangeAreaClick}
                displayArea={this.state.displayArea}
                handleVarsInput={this.handleVarsInput}
                variables={this.props.variables}
                elements={this.elements}
            />
        )
    }
}

export default CodeContainer