import React from 'react'
import './HideComponent.css';

function RenderComponent(props){
    return (
        <div
            className={'hideComponent'}
            style={{
                width: props.hide.width,
                left: props.hide.offset,
                display: props.hide.display,
            }}
            ref={props.elements.hide}
        >
            {props.hide.text}
        </div>
    )
}

export default RenderComponent