import React from 'react'
import DirectoryNode from '../DirectoryNode'
import ButtonComponent from "../Button";
import {useTranslation} from 'react-i18next'
import './DirectoryComponent.css';

function DirectoryComponent(props){
    const {t} = useTranslation('directory');
    return (
        <div className={'DirectoryComponent Component'}>
            <div className={'title'}>
                {t('labels.project directory')}
            </div>
            <div
                className={'DirectoryArea Area'}
                ref={props.elements.directoryArea}
                onScroll={props.handleControlsScroll}
            >
                <DirectoryNode
                    id={'node0'}
                    files={props.files}
                    children={props.files['node0'].children}
                    name={t('labels.project directory')}
                    handleControlsClick={props.handleControlsClick}
                    handleClickNode={props.handleClickNode}
                    openedControlsNodeId={props.openedControlsNodeId}
                    openedControlsTop={props.openedControlsTop}
                    openedFolderChildren={props.openedFolderChildren}
                />
            </div>
            <ButtonComponent
                className="fullwidth"
                displayBigger={true}
                onClick={props.handleLinkedInClick}
                control="code"
                text={t('actions.linkedIn')}
            />
            {
                props.uploadEnabled &&
                <div
                    className={"upload"}
                >
                    <input
                        ref={props.elements.uploadFile}
                        type={"file"}
                        accept={"text/*"}
                        onChange={props.handleUploadFileChange}
                    />
                    <div
                        className={"container"}
                    >
                        <label
                            htmlFor={"filename"}
                        >
                            {t('labels.filename')}
                        </label>
                        <input
                            id="filename"
                            type={"text"}
                            value={props.uploadFileName}
                            onChange={props.handleFileNameChange}
                        />
                    </div>
                    <button
                        onClick={props.handleUploadButtonClick}
                    >
                        {t('actions.upload')}
                    </button>
                    <button
                        onClick={props.resetUpload}
                    >
                        {t('actions.cancel')}
                    </button>
                </div>
            }
        </div>
    )
}

export default DirectoryComponent