import React, {Component} from 'react'
import DirectoryComponent from './DirectoryComponent'
import i18n from 'i18next';
import {nanoid} from 'nanoid';

const validFilename = require('valid-filename');

class DirectoryContainer extends Component {
    constructor(props){
        super(props);
        this.elements = {
            uploadFile: React.createRef(),
            directoryArea: React.createRef()
        }
        this.openedFolderChildren = localStorage.getItem('openedFolderChildren') ? JSON.parse(localStorage.getItem('openedFolderChildren')) : ['node0'];
        this.state = {
            openedControlsNodeId: null,
            openedControlsPositionVertical: 0,
            directoryAreaScroll: 0,
            openedFolderChildrenCheck: 0,
            upload: {
                enabled: false,
                name: '',
                parentId: 'node0'
            }
        }
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClickNode = this.handleClickNode.bind(this);
        this.handleControlsClick = this.handleControlsClick.bind(this);
        this.hideShowControls = this.hideShowControls.bind(this);
        this.deleteNode = this.deleteNode.bind(this);
        this.handleUploadFileChange = this.handleUploadFileChange.bind(this);
        this.handleFileNameChange = this.handleFileNameChange.bind(this);
        this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);
        this.resetUpload = this.resetUpload.bind(this);
        this.validateName = this.validateName.bind(this);
        this.handleControlsScroll = this.handleControlsScroll.bind(this);
        this.openedFolderAddOrRemove = this.openedFolderAddOrRemove.bind(this);
    }

    componentDidMount(){
        window.addEventListener("beforeunload", (event) => {
            this.pageClosing(event);
        })
    }

    //citace https://github.com/szymonkaliski/react-window-mixins/issues/2#issuecomment-133290242
    pageClosing(event){
        let canClose = this.props.localStorageSafeSetItem('openedFolderChildren', JSON.stringify(this.openedFolderChildren));
        if(!canClose){
            var confirmationMessage = i18n.t('app:labels.storage full');
            (event || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        }
    }

    hideShowControls(targetId = null){
        if(targetId === null){
            targetId = this.state.openedControlsNodeId;
        }
        let openedControlsNodeId = null;
        if(this.props.files[targetId] && this.state.openedControlsNodeId !== targetId){
            openedControlsNodeId = targetId;
            document.addEventListener('mousedown', this.handleClickOutside);
        }
        else if(targetId){
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
        this.setState(function(prevState){
            return {
                ...prevState,
                openedControlsNodeId: openedControlsNodeId
            }
        })
    }

    validateName(name, parentId){
        if(!validFilename(name)){
            return false;
        }
        let parentNodeChildren = this.props.files[parentId].children;
        for(let i = 0; i < parentNodeChildren.length; i++){
            if(this.props.files[parentNodeChildren[i]].name === name){
                return false
            }
        }
        return true;
    }

    handleControlsClick(event){
        if(event.target.classList.contains('controlsButton')){
            this.setState(prevState => {
                return {
                    ...prevState,
                    openedControlsPositionVertical: event.target.getBoundingClientRect().top + prevState.directoryAreaScroll,
                }
            })
            let targetId = event.target.parentElement.parentElement.parentElement.id;
            this.elements.openedControls = event.target.parentElement;
            this.hideShowControls(targetId);
        }
        else if(event.target.classList.contains('control')){
            let targetId = event.target.parentElement.classList.contains('controlsChildren')
                ? event.target.parentElement.parentElement.parentElement.parentElement.id
                : 'node0';
            let control = event.target.getAttribute('control');
            if(control === 'new file' || control === 'new folder'){
                this.controlNewNode(targetId, control === 'new file');
            }
            else if(control === 'upload file'){
                this.controlUploadFile(targetId);
            }
            else if(control === 'delete'){
                this.controlDeleteNode(targetId);
            }
            else if(control === 'download'){
                this.downloadNode(targetId);
            }
            this.hideShowControls();
        }
    }

    handleControlsScroll(event){
        this.setState(prevState => {
            return {
                ...prevState,
                directoryAreaScroll: this.elements.directoryArea.current.scrollTop
            }
        })
    }

    controlNewNode(targetId, file){
        let name = prompt(i18n.t('directory:labels.enter filename'));
        if(name === null){
            return;
        }

        if(!this.validateName(name, targetId)){
            this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('directory:notifications.error.name invalid'), 'danger');
            return;
        }
        let newNode = {
            name: name,
            parent: targetId
        };
        if(!file){
            newNode.children = [];
        }

        let newId = this.props.addFileOrFolder(newNode);
        if(!newId){
            this.props.addNotification(i18n.t('notificationLabels:app error'), file ? i18n.t('directory:notifications.error.creating file') : i18n.t('directory:notifications.error.creating folder'), 'danger');
            return;
        }

        if(file){
            let setItem = this.props.localStorageSafeSetItem(newId, JSON.stringify({
                type: 'text',
                data: {
                    lines: [{text: '', id: nanoid()}],
                    variables: '{}'
                }
            }));
            if(setItem){
                this.props.addNotification(i18n.t('notificationLabels:success'), i18n.t('directory:notifications.success.creating file'), 'success');
            }
        }
        else {
            this.openedFolderAddOrRemove(newId);
            this.props.addNotification(i18n.t('notificationLabels:success'), i18n.t('directory:notifications.success.creating folder'), 'success');
        }
    }

    controlUploadFile(targetId){
        this.props.elements.hide.current.addEventListener('mousedown', () => {
            this.resetUpload();
        });
        this.setState(function(prevState){
            return {
                upload: {
                    ...prevState.upload,
                    enabled: true,
                    parentId: targetId
                }
            }
        });
        this.props.setHide({
            preset: "all",
            text: ""
        });
    }

    controlDeleteNode(targetId){
        if(typeof this.props.files[targetId] === 'undefined'){
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('directory:notifications.error.delete node'), 'danger');
        }
        else {
            this.deleteNode(this.props.files, targetId)
            this.props.setFiles();
            this.props.addNotification(i18n.t('notificationLabels:success'), i18n.t('directory:notifications.success.delete node'), 'success');
        }
    }

    getNodeText(id){
        let lines;
        if(id === this.props.selectedNodeId){
            lines = this.props.lines;
        }
        else {
            [lines] = this.props.getFileFromStorage(id);
        }
        let result = '';
        if(lines && lines.length > 0){
            result = lines.map(entry => entry.text).join('\n');
        }
        return result;
    }

    downloadNode(targetId){
        if(typeof this.props.files[targetId] === 'undefined'){
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('directory:notifications.error.file unknown'), 'danger');
        }
        else if(typeof this.props.files[targetId].children !== 'undefined'){
            let children = this.props.files[targetId].children;
            for(let i = 0; i < children.length; i++){
                this.downloadNode(children[i]);
            }
        }
        else if(typeof this.props.files[targetId].name !== 'undefined'){
            this.props.downloadText(this.getNodeText(targetId), this.props.files[targetId].name);
        }
    }

    deleteNode(files, nodeId){
        if(typeof files[nodeId] === 'undefined'){
            return;
        }
        while(typeof files[nodeId].children !== 'undefined'
        && files[nodeId].children.length){
            this.deleteNode(files, files[nodeId].children[0]);
        }
        if(nodeId === this.props.selectedNodeId){
            this.props.resetSelectedNode();
        }
        if(nodeId !== 'node0'){
            localStorage.removeItem(nodeId);
            files[files[nodeId].parent].children.splice(files[files[nodeId].parent].children.indexOf(nodeId), 1);
            delete files[nodeId];
        }
    }

    handleClickOutside(event){
        if(!this.elements.openedControls.contains(event.target)){
            this.hideShowControls();
        }
    }

    openedFolderAddOrRemove(id){
        let updateState = false;
        let foundIndex = this.openedFolderChildren.indexOf(id);
        if(foundIndex > -1){
            this.openedFolderChildren.splice(foundIndex, 1);
            updateState = true;
        }
        else if(typeof this.props.files[id] !== 'undefined'){
            this.openedFolderChildren.push(id);
            updateState = true;
        }
        if(updateState){
            this.setState(function(prevState){
                return {
                    ...prevState,
                    openedFolderChildrenCheck: prevState.openedFolderChildrenCheck + 1
                }
            });
        }
    }

    handleClickNode(event){
        if(event.button === 2 && event.target.classList.contains('node')){
            event.target = event.target.querySelector('.controlsButton');
            this.handleControlsClick(event);
        }
        else if(event.target === event.currentTarget){
            let targetId = event.target.parentElement.id;
            if(targetId === 'node0'){
                // add node0 for users who used app before commit f1515ea
                if(this.openedFolderChildren.indexOf('node0') === -1){
                    this.openedFolderAddOrRemove(targetId);
                }
                return;
            }
            if(event.target.parentElement.classList.contains('folder')){
                this.openedFolderAddOrRemove(targetId);
            }
            else if(typeof this.props.files[targetId] !== 'undefined' && targetId !== this.props.selectedNodeId){
                if(this.props.saveSelectedNode()){
                    this.props.setSelectedNode(targetId, this.props.files[targetId].name);
                }
            }
        }
    }

    handleUploadFileChange(event){
        let file = event.target.files[0];
        if(file.type.split('/')[0] !== 'text'){
            this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('directory:notifications.error.file not text'), 'danger');
            event.target.value = null;
        }
        else {
            this.setState(function(prevState){
                return {
                    upload: {
                        ...prevState.upload,
                        name: file.name
                    }
                }
            })
        }
    }

    handleLinkedInClick(){
        window.open('https://www.linkedin.com/in/stepan-strba/', '_blank', 'noreferrer');
    }

    handleFileNameChange(event){
        let newName = event.target.value;
        this.setState(function(prevState){
            return {
                upload: {
                    ...prevState.upload,
                    name: newName
                }
            }
        })
    }

    handleUploadButtonClick(event){
        if(!this.elements.uploadFile.current.files.length){
            this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('directory:notifications.error.file not selected'), 'danger');
            return;
        }
        if(!this.validateName(this.state.upload.name, this.state.upload.parentId)){
            this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('directory:notifications.error.name invalid'), 'danger');
            return;
        }
        if(typeof this.props.files[this.state.upload.parentId] === 'undefined'){
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('directory:notifications.error.folder invalid'), 'danger');
            return;
        }
        let file = this.elements.uploadFile.current.files[0];
        let newNode = {
            name: this.state.upload.name,
            parent: this.state.upload.parentId
        };
        let newId = this.props.addFileOrFolder(newNode);
        if(newId === false){
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('directory:notifications.error.creating file'), 'danger');
        }
        else {
            let reader = new FileReader();
            reader.addEventListener('load', () => {
                let setItem = this.props.localStorageSafeSetItem(newId, JSON.stringify({
                    lines: reader.result.replace(/[\r]/g, '').split('\n').map(text => {
                        return {text: text, id: nanoid()}
                    }),
                    variables: '{}'
                }));
                if(setItem){
                    this.props.addNotification(i18n.t('notificationLabels:success'), i18n.t('directory:notifications.success.uploaded file'), 'success');
                }
                else {
                    this.deleteNode(this.props.files, newId)
                    this.props.setFiles();
                }
                this.resetUpload();
            })
            reader.readAsBinaryString(file);
        }
    }

    resetUpload(){
        this.props.elements.hide.current.removeEventListener('mousedown', this.resetUpload);
        this.elements.uploadFile.current.value = '';
        this.setState({
            upload: {
                enabled: false,
                name: '',
                parentId: 'node0'
            }
        });
        this.props.setHide({
            preset: "none",
            text: ""
        });
    }

    render(){
        return (
            <DirectoryComponent
                handleControlsClick={this.handleControlsClick}
                handleControlsScroll={this.handleControlsScroll}
                handleClickNode={this.handleClickNode}
                children={this.props.files['node0'].children}
                files={this.props.files}
                openedControlsNodeId={this.state.openedControlsNodeId}
                openedControlsTop={this.state.openedControlsPositionVertical - this.state.directoryAreaScroll}
                openedFolderChildren={this.openedFolderChildren}
                uploadFileName={this.state.upload.name}
                uploadEnabled={this.state.upload.enabled}
                handleUploadFileChange={this.handleUploadFileChange}
                handleFileNameChange={this.handleFileNameChange}
                handleUploadButtonClick={this.handleUploadButtonClick}
                handleLinkedInClick={this.handleLinkedInClick}
                resetUpload={this.resetUpload}
                elements={this.elements}
            />
        )
    }
}

export default DirectoryContainer