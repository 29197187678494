import React, {Component} from 'react'
import RenderComponent from './RenderComponent'
import axios from 'axios'
import i18n from "i18next";

class RenderContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            renderedText: null,
            renderedName: null
        }
        this.handleRenderButton = this.handleRenderButton.bind(this);
        this.getSelectedTemplatePath = this.getSelectedTemplatePath.bind(this);
        this.getTemplateData = this.getTemplateData.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.handleDownloadButton = this.handleDownloadButton.bind(this);
    }

    setRendered(newRenderedText = '', newRenderedName = null){
        this.setState(function(prevState){
            return {
                ...prevState,
                renderedText: newRenderedText,
                renderedName: newRenderedName
            }
        })
    }

    // todo maybe add loading
    handleRenderButton(){
        let selectedNode = this.props.files[this.props.selectedNodeId];
        if(!selectedNode){
            this.setRendered();
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('render:notifications.error.file not found'), 'danger');
            return;
        }
        else if(typeof selectedNode.children !== 'undefined'){
            this.setRendered();
            this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('render:notifications.error.render folder'), 'danger');
            return;
        }
        let context = {};
        try{
            context = JSON.parse(this.props.variables);
        } catch(error){
            this.setRendered();
            this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('render:notifications.error.variables not json'), 'danger');
            return;
        }
        let selectedTemplatePath = selectedNode.name;
        if(selectedNode.parent && selectedNode.parent !== 'node0'){
            selectedTemplatePath = this.getSelectedTemplatePath(this.props.files[selectedNode.parent], selectedTemplatePath);
        }
        let sendBody = {
            templates: this.getTemplates(),
            context: context,
            selected: selectedTemplatePath
        };
        this.props.setHide({
            preset: "coderender",
            text: "Loading"
        })
        axios.post(process.env.REACT_APP_API_URL, sendBody, {
            transformResponse: [(data) => {
                return data;
            }],
        })
            .then(response => {
                this.setRendered(response.data, selectedNode.name);
                this.props.addNotification(i18n.t('notificationLabels:success'), i18n.t('render:notifications.success.render'), 'success');
            })
            .catch(reason => {
                if(!reason.response){
                    this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('render:notifications.error.render error'), 'danger');
                }
                else if(reason.response.status === 400){
                    this.setRendered(reason.response.data, selectedNode.name);
                    this.props.addNotification(i18n.t('notificationLabels:user error'), i18n.t('render:notifications.error.wrong'), 'danger');
                }
                else if(reason.response.status === 500){
                    this.setRendered(reason.response.data, selectedNode.name);
                    this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('render:notifications.error.wrong'), 'danger');
                }
                else {
                    this.props.addNotification(i18n.t('notificationLabels:app error'), i18n.t('render:notifications.error.render error'), 'danger');
                }
            })
            .finally(() => {
                this.props.setHide();
            });
    }

    getSelectedTemplatePath(selected, path){
        if(selected && !selected.parent){
            return path;
        }
        else {
            return this.getSelectedTemplatePath(this.props.files[selected.parent], selected.name + '/' + path)
        }
    }

    getTemplateData(key){
        if(this.props.selectedNodeId === key){
            return this.props.lines.map(entry => entry.text).join('\n');
        }
        else {
            const [lines] = this.props.getFileFromStorage(key);
            return lines.map(entry => entry.text).join('\n');
        }
    }

    getTemplates(files = this.props.files['node0'].children, templates = {}, path = ''){
        for(let key of files){
            if(this.props.files[key].children){
                templates = this.getTemplates(this.props.files[key].children, templates, path + this.props.files[key].name + '/');
            }
            else {
                templates[path + this.props.files[key].name] = this.getTemplateData(key);
            }
        }
        return templates;
    }

    handleDownloadButton(event){
        this.props.downloadText(this.state.renderedText, 'rendered ' + this.state.renderedName);
    }

    render(){
        return (
            <RenderComponent
                handleRenderButton={this.handleRenderButton}
                handleDownloadButton={this.handleDownloadButton}
                renderedText={this.state.renderedText}
                renderedName={this.state.renderedName}
            />
        )
    }
}

export default RenderContainer