import React from 'react'
import './ButtonComponent.css';

function ButtonComponent(props){
    return (
        <div
            className={
                'control'
                + (props.displayBigger ? ' bigger' : '')
                + (props.displayChosen ? ' chosen' : '')
                + (props.displayBorder ? ' border' : '')
                + (props.displayLocked ? ' locked' : '')
                + (props.className ? ' ' + props.className : '')
            }
            onClick={props.displayLocked ? undefined : props.onClick}
            control={props.control}
        >
            {props.text}
        </div>
    )
}

export default ButtonComponent