import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                app: {
                    labels: {
                        'storage full': 'Can\'t save files because local storage is full, delete some files to free up space.',
                    },
                    notifications: {
                        error: {
                            'storage full': 'Local storage is full, delete some files to free up space',
                            'localstorage': 'File not saved'
                        },
                    },
                },
                code: {
                    actions: {
                        'code': 'Code',
                        'variables': 'Variables',
                    },
                },
                directory: {
                    actions: {
                        'cancel': 'Cancel',
                        'delete': 'Delete',
                        'delete all': 'Delete all',
                        'download': 'Download',
                        'download all': 'Download all',
                        'new file': 'New file',
                        'new folder': 'New folder',
                        'upload': 'Upload!',
                        'upload file': 'Upload file',
                        'linkedIn': '© Štěpán Štrba',
                    },
                    labels: {
                        'enter filename': 'Enter filename',
                        'filename': 'Filename:',
                        'project directory': 'Project directory',
                    },
                    notifications: {
                        error: {
                            'creating file': 'Couldn\'t create file',
                            'creating folder': 'Couldn\'t create folder',
                            'name invalid': 'Invalid name',
                            'delete node': 'Can\'t delete this node',
                            'file not selected': 'Not selected any file',
                            'file not text': 'Not a text file',
                            'file unknown': 'Unknown file',
                            'folder invalid': 'Invalid folder',
                        },
                        success: {
                            'creating file': 'File created',
                            'creating folder': 'Folder created',
                            'delete node': 'Delete successful',
                            'uploaded file': 'File uploaded',
                        },
                    },
                },
                hide: {
                    labels: {
                        'file not selected': 'No file selected.',
                        'loading': 'loading',
                    },
                },
                render: {
                    actions: {
                        'download render': 'Download render',
                        'render': 'Render',
                    },
                    labels: {
                        'rendered {what}': 'Rendered {{what}}',
                        'rendered nothing': 'Nothing rendered',
                    },
                    notifications: {
                        error: {
                            'file not found': 'Didn\'t find selected file',
                            'render folder': 'Can\'t render folders',
                            'variables not json': 'File variables doesn\'t match json',
                            'render error': 'Can\'t render at the time',
                            'wrong': 'Error shown in rendered'
                        },
                        success: {
                            'render': 'Template was successfully rendered'
                        }
                    },
                },
                notificationLabels: {
                    'app error': 'App error',
                    'user error': 'User error',
                    'success': 'Success',
                }
            }
        },
        lng: 'en',
        defaultNS: '',
        react: {
            transKeepBasicHtmlNodesFor: [],
            transSupportBasicHtmlNodes: false
        }
    });


export default i18n;